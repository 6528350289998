<template>
    <div class="inner_pages" id="checkout_allSubscriptions">
        <sidebar-component  v-model="showSidebar"/>
        <div class="container">
            <div class="respective_content">
                <div class="acton_header m-0">
                    <button type="button" class="bar_menu" @click="showSidebar = !showSidebar"><img src="@/assets/images/bar_menu.svg"></button>
                    <h2>Subscriptions</h2>
                    <div class="search_area">
                        <input type="text" @input="isTyping = true" v-model.trim="params.search" placeholder="Search by customer name, email" />
                        <button type="button" class="search_btn"><i class="fas fa-search"></i></button>
                        <a class="cleaner" @click="params.search = ''; isTyping = true;" v-if="searchField && params.search"><i class="fas fa-times"></i></a>
                    </div>
                    <!-- <button type="button" class="video_btn" @click="tutorial = true;"><i class="fas fa-play"></i></button> -->
                </div>
                <div class="result_wpr new">
                    <div class="actions mb-0">
                        <ul class="left">
                            <li class="optionDrops contacts-tabs tutorial_btn" @click="tutorialList = !tutorialList;" v-click-outside="closeTutorialListDropdown">
                                <label>Tutorials</label><img src="@/assets/images/play.svg">
                                <div class="dropdown_wpr" :class="tutorialList ? 'active' : ''">
                                    <ul>
                                        <li @click="tutorial = true;">How to use the subscriptions feature</li>
                                    </ul>
                                </div>
                            </li>
                        </ul>
                        <ul class="right">
                            <li class="optionDrops contacts-tabs" @click="productType = !productType" v-click-outside="closeProductType">
                                {{ productTypeFilterName ? productTypeFilterName : 'All' }}<i class="fas fa-angle-down"></i>
                                <div class="dropdown_wpr" :class="productType ? 'active' : ''">
                                    <ul>
                                        <li @click="handleProductTypeFilter('new')">New Signups</li>
                                        <li @click="handleProductTypeFilter('active')">Active</li>
                                        <li @click="handleProductTypeFilter('trialing')">Trial</li>
                                        <li @click="handleProductTypeFilter('past_due')">Overdue</li>
                                        <li @click="handleProductTypeFilter('incomplete')">Paused</li>
                                        <li @click="handleProductTypeFilter('cancelled')">Cancelled</li>
                                        <li @click="handleProductTypeFilter('completed')">Completed</li>
                                    </ul>
                                </div>
                            </li>
                            <li class="optionDrops contacts-tabs capitalize fixed_height" @click="orderType = !orderType" v-click-outside="closeOrderType">
                                {{ productFilterName ? productFilterName : 'Select Product' }}<i class="fas fa-angle-down"></i>
                                <div class="dropdown_wpr" :class="orderType ? 'active' : ''">
                                    <ul>
                                        <li @click="handleProductFilter()">Select Product</li>
                                        <li v-for="(product, id) in subscriptionProducts" :key="id" @click="handleProductFilter(product, id)">{{ product }}</li>
                                    </ul>
                                </div>
                            </li>
                            <li class="optionDrops contacts-tabs fixed_height duration" @click="handleDurationType()" v-click-outside="closeDurationType">
                                {{ durationTitle }}<i class="fas fa-angle-down"></i>
                                <div class="dropdown_wpr" :class="durationType ? 'active' : ''">
                                    <ul>
                                        <li v-for="(duration, d) of durations" :key="d" @click="handleDuration(duration)">{{ duration }}</li>
                                    </ul>
                                </div>
                                <div class="custom_range" :class="{'active': isCustomRange }" @click.stop="event && event.stopPropagation ? event.stopPropagation() : ''">
                                    <h4>Custom Range</h4>
                                    <ul>
                                        <li>
                                            <label for="">Start Date</label>
                                            <datepicker v-model="params.start_date" :max-date="maxStartDate" auto-apply placeholder="MM/DD/YYYY" format="MM/dd/yyyy"></datepicker>
                                        </li>
                                        <li>
                                            <label for="">End Date</label>
                                            <datepicker v-model="params.end_date" :min-date="minEndDate" auto-apply placeholder="MM/DD/YYYY" format="MM/dd/yyyy"></datepicker>
                                        </li>
                                        <li>
                                            <button type="button" class="btn save_btn" @click="handleCustomDateRange()">Apply</button>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                            <li class="filter_btn" @click="mobile_filter = true;">
                                <span></span>
                                <span></span>
                                <span></span>
                            </li>
                            <!-- <li class="search_area ml-auto" :class="{'active': searchField}">
                                <input type="text" @input="isTyping = true" v-model.trim="params.search" placeholder="Search by customer name, email" />
                                <button type="button" class="search_btn" @click="searchField = !searchField;"><i class="fas fa-search"></i></button>
                                <a class="cleaner" @click="params.search = ''; isTyping = true;" v-if="searchField && params.search"><i class="fas fa-times"></i></a>
                            </li> -->
                        </ul>
                    </div>
                    <div class="list_wpr">
                        <ul class="order_list">
                            <li>
                                <div class="order_card">
                                    <div>
                                        <h5>Subscriptions</h5>
                                        <h3>{{ allSubscriptions.total ? allSubscriptions.total : 0 }}</h3>
                                    </div>
                                    <div class="icon">
                                        <i :class="`fas fa-shopping-bag`"></i>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="order_card">
                                    <div>
                                        <h5>MRR</h5>
                                        <h3>{{ companyCurrencySymbol }}{{ subscriptionStats.mrr ? subscriptionStats.mrr : '0' }}</h3>
                                    </div>
                                    <div class="icon">
                                        <h4>{{ companyCurrencySymbol }}</h4>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="order_card">
                                    <div>
                                        <h5>Yearly Revenue</h5>
                                        <h3>{{ companyCurrencySymbol }}{{ subscriptionStats.yearly_revenue ? subscriptionStats.yearly_revenue : '0.00' }}</h3>
                                    </div>
                                    <div class="icon">
                                        <h4>{{ companyCurrencySymbol }}</h4>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="header_filter" :class="{'show' : mobile_filter}">
                    <button class="close_btn" @click="mobile_filter = false;"><i class="fas fa-times"></i></button>
                    <div class="action_area">
                        <h3 class="sub_heading2 mb-4">Filters</h3>
                        <ul>
                            <li class="optionDrops contacts-tabs" @click="productType2 = !productType2" v-click-outside="closeProductType2">
                                {{ productTypeFilterName ? productTypeFilterName : 'All' }}<i class="fas fa-angle-down"></i>
                                <div class="dropdown_wpr" :class="productType2 ? 'active' : ''">
                                    <ul>
                                        <li @click="handleProductTypeFilter('new')">New Signups</li>
                                        <li @click="handleProductTypeFilter('active')">Active</li>
                                        <li @click="handleProductTypeFilter('trialing')">Trial</li>
                                        <li @click="handleProductTypeFilter('past_due')">Overdue</li>
                                        <li @click="handleProductTypeFilter('incomplete')">Paused</li>
                                        <li @click="handleProductTypeFilter('cancelled')">Cancelled</li>
                                        <li @click="handleProductTypeFilter('completed')">Completed</li>
                                    </ul>
                                </div>
                            </li>
                            <li class="optionDrops contacts-tabs capitalize" @click="orderType2 = !orderType2" v-click-outside="closeOrderType2">
                                {{ productFilterName ? productFilterName : 'Select Product' }}<i class="fas fa-angle-down"></i>
                                <div class="dropdown_wpr" :class="orderType2 ? 'active' : ''">
                                    <ul>
                                        <li @click="handleProductFilter()">Select Product</li>
                                        <li v-for="(product, id) in subscriptionProducts" :key="id" @click="handleProductFilter(product, id)">{{ product }}</li>
                                    </ul>
                                </div>
                            </li>
                            <li class="optionDrops contacts-tabs fixed_height" @click="handleDurationType2()" v-click-outside="closeDurationType2">
                                {{ durationTitle }}<i class="fas fa-angle-down"></i>
                                <div class="dropdown_wpr" :class="durationType2 ? 'active' : ''">
                                    <ul>
                                        <li v-for="(duration, d) of durations" :key="d" @click="handleDuration(duration)">{{ duration }}</li>
                                    </ul>
                                </div>
                                <div class="custom_range" :class="{'active': isCustomRange }" @click.stop="event && event.stopPropagation ? event.stopPropagation() : ''">
                                    <h4>Custom Range</h4>
                                    <ul>
                                        <li>
                                            <label for="">Start Date</label>
                                            <datepicker v-model="params.start_date" :max-date="maxStartDate" auto-apply placeholder="MM/DD/YYYY" format="MM/dd/yyyy"></datepicker>
                                        </li>
                                        <li>
                                            <label for="">End Date</label>
                                            <datepicker v-model="params.end_date" :min-date="minEndDate" auto-apply placeholder="MM/DD/YYYY" format="MM/dd/yyyy"></datepicker>
                                        </li>
                                        <li>
                                            <button type="button" class="btn save_btn" @click="handleCustomDateRange()">Apply</button>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="filter result_wpr new">
                    <div class="actions mt-4">
                        <ul>
                            <li class="search_area ml-auto" :class="{'active': searchField2}">
                                <input type="text" placeholder="Search..." @input="isTyping = true" v-model.trim="params.search"/>
                                <button class="search_btn" @click="searchField2 = !searchField2;">
                                    <i class="fas fa-search"></i>
                                </button>
                            </li>
                        </ul>
                    </div>
                    <div class="contact_loader" v-if="allSubscriptionsLoader"><quote-loader /></div>
                    <div class="scroll_table">
                        <table class="show_header" v-show="!allSubscriptionsLoader">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th align="left">Product</th>
                                    <th>Actions</th>
                                    <th align="right">Amount</th>
                                </tr>
                            </thead>
                            <tbody v-if="allSubscriptions.total">
                                <tr v-for="(subscription, s) in allSubscriptions.data" :key="s">
                                    <td>
                                        <div class="user_wpr" @click="handleSubscriptionDetails(subscription.client)">
                                            <div class="user_img m-0">
                                                <img :src="subscription.client &&  subscription.client.profile_pic ? subscription.client.profile_pic : require('@/assets/images/male-female.png')" alt="">
                                            </div>
                                        </div>
                                    </td>
                                    <td align="left">
                                        <h4>{{ subscription.product && subscription.product.name ? subscription.product.name : '-' }}
                                            <span v-if="subscription.product && subscription.product.pricing_type == 2">Recurring payment of {{ companyCurrencySymbol }}{{ parseFloat(subscription.product.product_price.total).toFixed(2) }}{{ subscription.status == 'trialing' ? ' (Trialing)' : '' }}</span>
                                            <span v-if="subscription.product && subscription.product.pricing_type == 3">Split payment of {{ companyCurrencySymbol }}{{ parseFloat(subscription.product.product_price.total).toFixed(2) }}</span>
                                        </h4>
                                        <h6>{{ subscription.client &&  subscription.client.full_name ? subscription.client.full_name : '-' }}</h6>
                                        <h6>{{ subscription.client &&  subscription.client.email ? subscription.client.email : '-' }}</h6>
                                    </td>
                                    <td>
                                        <ul class="action_list">
                                            <li title="Refund" @click="handleRefund(subscription)"><i class="fas fa-reply"></i></li>
                                            <li title="Send Invoice" @click="handleSendInvoice(subscription)"><i :class="{ 'fas fa-paper-plane': sendInvoiceLoader == 0 || sendInvoiceLoader != (subscription.status != 'trialing' ? subscription.id : subscription.order_id), 'fa fa-spin fa-spinner': sendInvoiceLoader == (subscription.status != 'trialing' ? subscription.id : subscription.order_id) }"></i></li>
                                            <li title="More" @click="handleSubscriptionDetails(subscription.client)"><i class="fas fa-user"></i></li>
                                        </ul>
                                    </td>
                                    <td align="right">
                                        <h4 v-if="subscription.product && subscription.product.pricing_type == 2">{{ companyCurrencySymbol }}{{ parseFloat(subscription.price).toFixed(2) }}</h4>
                                        <h4 v-if="subscription.product && subscription.product.pricing_type == 3">{{ companyCurrencySymbol }}{{ parseFloat(subscription.price).toFixed(2) }}</h4>
                                        <h6>{{ moment(subscription.created_at).format('ll') }}</h6>
                                        <h6>{{ moment(subscription.created_at).format('LT') }}</h6>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody v-else>
                                <tr>
                                    <td colspan="4" class="px-4 text-center" style="border-radius: 0 0 10px 10px;">You do not have access to any subscription products in this account.</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="table_footer">
                        <ul>
                            <li>{{ allSubscriptions.from ? allSubscriptions.from : 0 }} - {{ allSubscriptions.to ? allSubscriptions.to : 0 }} of {{ allSubscriptions.total }}</li>
                        </ul>
                    </div>
                    <div class="pagination" v-show="allSubscriptions.total">
                        <pagination v-model="params.page" :pages="allSubscriptions.last_page" @update:modelValue="handlePagination" />
                    </div>
                </div>
            </div>
        </div>
        <refund-component v-model="refundPayment" :refund-amount="refundAmount" :order-id="refundOrderId" :subscription-id="subscriptionId" :is-subscription="true" :refresh-records="handleRefreshRecords" />
        <profile-component v-model="details" :selected-client="selectedClient" />
        <video-tutorial v-model="tutorial" :tutorial-video="tutorialVideo"></video-tutorial>
    </div>
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { mapState, mapActions } from 'vuex'

    import Helper from '@/utils/Helper'
    import moment from 'moment'
    import Swal from 'sweetalert2'

    const VideoTutorial = defineAsyncComponent(() => import('@/components/VideoTutorial'))
    const SidebarComponent = defineAsyncComponent(() => import('@/pages/checkout/components/Sidebar'))
    const RefundComponent = defineAsyncComponent(() => import('@/pages/checkout/components/Refund'))
    const ProfileComponent = defineAsyncComponent(() => import('@/pages/checkout/components/client/Profile'))

    export default {
        name: 'Orders',

        data () {
            return {
                searchTxt: '',
                durationTitle: 'Today',
                durations: [
                    'Today',
                    'Yesterday',
                    'Last 7 Days',
                    'Last 14 Days',
                    'Last 30 Days',
                    'This Month',
                    'Last Month',
                    'This Year',
                    'All Time',
                    'Custom Range',
                ],
                maxStartDate: '',
                minEndDate: '',
                details: false,
                refundPayment: false,
                tutorialList: false,
                tutorial: false,
                tutorialVideo: 'https://www.loom.com/embed/fc5cae7e4abb4dffabeaf91f8054edc0?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true',
                selectedClient: {},
                isCustomRange:false,
                moment,
                orderType: false,
                orderType2: false,
                productType: false,
                productType2: false,
                durationType: false,
                durationType2: false,
                params: {
                    type: 'new',
                    product_id: '',
                    start_date:  moment.utc().format('YYYY-MM-DD'),
                    end_date:  moment.utc().format('YYYY-MM-DD'),
                    search: '',
                },
                isTyping: '',
                refundAmount: 0,
                refundOrderId: 0,
                subscriptionId: 0,
                searchField: false,
                searchField2: false,
                productFilterName: '',
                productTypeFilterName: '',
                mobile_filter: false,
                showSidebar: false,
            }
        },

        components: {
            SidebarComponent,
            ProfileComponent,
            RefundComponent,
            VideoTutorial
        },

        watch: {
            'params.search' (val) {
                const vm = this;

                vm.params.page = 1;

                setTimeout(() => {
                    vm.isTyping = false;
                }, 1500);
            },

            isTyping (val) {
                const vm = this;

                if (!val) {
                    if (vm.params.search != null && (vm.params.search.length >= 2 || vm.params.search.length == 0)) {
                        vm.getAllSubscriptions(vm.params);
                    }
                }
            },
        },

        computed: mapState({
            allSubscriptions: state => state.checkoutModule.allSubscriptions,
            allSubscriptionsLoader: state => state.checkoutModule.allSubscriptionsLoader,
            subscriptionProducts: state => state.checkoutModule.subscriptionProducts,
            sendInvoiceLoader: state => state.checkoutModule.sendInvoiceLoader,
            companyCurrencySymbol: state => state.checkoutModule.companyCurrencySymbol,
            subscriptionStats: state => state.checkoutModule.subscriptionStats,
        }),

        mounted () {
            const vm = this;

            if (!vm.subscriptionProducts.length) {
                vm.getSubscriptionProducts();
            }

            vm.getAllSubscriptions(vm.params);
        },

        methods: {
            ...mapActions({
                getAllSubscriptions: 'checkoutModule/getAllSubscriptions',
                getSubscriptionProducts: 'checkoutModule/getSubscriptionProducts',
                sendSubscriptionInvoice: 'checkoutModule/sendSubscriptionInvoice',
                sendOrderInvoice: 'checkoutModule/sendOrderInvoice',
            }),

            handleDuration (duration) {
                const vm = this;

                setTimeout(() => {
                    vm.durationTitle = duration;

                    if (duration == 'Custom Range') {
                        vm.isCustomRange = !vm.isCustomRange;
                    } else {
                        const filter = Helper.filterDates(duration);

                        vm.params.start_date = filter.start_date;
                        vm.params.end_date   = filter.end_date;
                        vm.isCustomRange     = false;

                        vm.getAllSubscriptions(vm.params);
                    }
                }, 50)
            },

            handleSelectDuration () {
                const vm = this;

                vm.isCustomRange = vm.durationTitle == 'Custom Range' ? !vm.isCustomRange : vm.isCustomRange;
            },

            handleCustomDateRange () {
                const vm = this;

                vm.isCustomRange     = false;
                vm.params.start_date =  moment(vm.params.start_date).isValid() ? moment(vm.params.start_date).format('YYYY-MM-DD') : '';
                vm.params.end_date   =  moment(vm.params.end_date).isValid() ? moment(vm.params.end_date).format('YYYY-MM-DD') : '';

                vm.getAllSubscriptions(vm.params);
            },

            handlePagination (page) {
                const vm = this;

                vm.params.page = page;
                vm.getAllSubscriptions(vm.params);
            },

            handleSubscriptionDetails (client) {
                const vm = this;

                vm.selectedClient = client;
                vm.details        = true;
            },

            handleRefund (subscription) {
                const vm = this;

                const lastSubscriptionLog = subscription.subscription_logs && subscription.subscription_logs.length ? subscription.subscription_logs[0] : null;

                if ((subscription.status == 'trialing' && subscription.is_trial_refund) || (lastSubscriptionLog && lastSubscriptionLog.is_refunded)) {
                    Swal.fire(Helper.swalWarningOptions('Oops!', `This order has already been refunded, you can not refund it again.`, 'OK'));
                } else {
                    vm.refundAmount    = parseFloat(subscription.price).toFixed(2);
                    vm.refundOrderId   = subscription.order_id;
                    vm.subscriptionId  = lastSubscriptionLog ? lastSubscriptionLog.id : 0;
                    vm.refundPayment   = true;
                }
            },

            handleRefreshRecords () {
                const vm = this;

                vm.getAllSubscriptions(vm.params);
            },

            handleSendInvoice (subscription) {
                const vm = this;

                if (subscription.status != 'trialing') {
                    vm.sendSubscriptionInvoice(subscription.id);
                } else {
                    vm.sendOrderInvoice(subscription.order_id);
                }
            },

            closeOrderType () {
                const vm = this;

                vm.orderType = false;
            },

            closeOrderType2 () {
                const vm = this;

                vm.orderType2 = false;
            },

            closeProductType () {
                const vm = this;

                vm.productType = false;
            },

            closeProductType2 () {
                const vm = this;

                vm.productType2 = false;
            },

            closeDurationType () {
                const vm = this;

                vm.durationType  = false;
                vm.isCustomRange = false
            },

            closeDurationType2 () {
                const vm = this;

                vm.durationType2  = false;
                vm.isCustomRange = false
            },

            closeTutorialListDropdown(){
                const vm = this;

                vm.tutorialList = false;
            },

            handleProductFilter (product = '', id = '') {
                const vm = this;

                vm.productFilterName = product ? product : '';
                vm.params.product_id = id ? id : '';

                vm.getAllSubscriptions(vm.params);
            },

            handleProductTypeFilter (type) {
                const vm = this;

                if (type == 'new') {
                    vm.productTypeFilterName = 'New Signups';
                    vm.params.type = 'new';
                } else if (type == 'active') {
                    vm.productTypeFilterName = 'Active';
                    vm.params.type = 'active';
                } else if (type == 'trialing') {
                    vm.productTypeFilterName = 'Trial';
                    vm.params.type = 'trialing';
                } else if (type == 'past_due') {
                    vm.productTypeFilterName = 'Overdue';
                    vm.params.type = 'past_due';
                } else if (type == 'incomplete') {
                    vm.productTypeFilterName = 'Paused';
                    vm.params.type = 'incomplete';
                } else if (type == 'cancelled') {
                    vm.productTypeFilterName = 'Cancelled';
                    vm.params.type = 'cancelled';
                } else if (type == 'completed') {
                    vm.productTypeFilterName = 'Completed';
                    vm.params.type = 'completed';
                }

                vm.getAllSubscriptions(vm.params);
            },

            handleDurationType () {
                const vm = this;

                if (!vm.durationType && vm.isCustomRange) {
                    vm.isCustomRange = false;
                }

                vm.durationType = !vm.durationType;
            },

            handleDurationType2 () {
                const vm = this;

                if (!vm.durationType2 && vm.isCustomRange) {
                    vm.isCustomRange = false;
                }

                vm.durationType2 = !vm.durationType2;
            }
        }
    }
</script>

<style scoped>
    .acton_header .search_area{
        margin-left: auto;
    }
    .bar_menu {
        height: 18px;
        width: 18px;
        cursor: pointer;
        margin-right: 10px;
        margin-left: 0 !important;
        display: none;
    }
    .container{
        max-width: 1200px;
        width: 100%;
        margin: 0 auto;
    }
    .video_player .modal_body {
        width: 100%;
        height: 400px;
        display: flex;
        padding: 15px;
        align-content: center;
        justify-content: center;
        align-items: center;
    }
    .actions > ul li.duration{
        position: relative;
    }
    .actions > ul li.duration .dropdown_wpr{
        left: auto;
        right: 1px;
    }
    .result_wpr.new{
        overflow: visible;
    }
    .custom_range {
        text-align: left;
        background: #fff;
        position: absolute;
        top: 100%;
        right: 0;
        width: 200px;
        box-shadow: 0 1px 20px rgba(0, 0, 0, 0.15);
        border: 1px solid #E0E0E0;
        border-radius: 5px;
        margin-top: 1px;
        display: none;
        opacity: 0;
        z-index: 1;
    }
    .custom_range.active{
        animation: dropMove 0.5s ease-in-out;
        animation-timing-function: cubic-bezier(1.0, 0.5);
        display: block;
        opacity: 1;
    }
    .custom_range:before{
        content: '';
        position: absolute;
        right: 17px;
        top: -6px;
        width: 10px;
        height: 10px;
        border-top: 1px solid #E0E0E0;
        border-left: 1px solid #E0E0E0;
        background: #fff;
        transform: rotate(45deg);
    }
    .custom_range h4{
        padding: 10px 15px;
        font-size: 15px;
        line-height: 20px;
        color: #121525;
        font-weight: 500;
        border-bottom: 1px solid #ececec;
    }
    .custom_range ul{
        display: flex;
        flex-direction: column;
        margin: 10px 0 15px;
        line-height: 0;
        gap: 12px;
    }
    .custom_range ul li{
        border: 0;
        padding: 5px 15px;
        height: auto;
        margin: 0;
        width: 100%;
        display: block;
    }
    .custom_range ul li label{
        font-size: 11px;
        line-height: 14px;
        font-weight: 500;
        margin-bottom: 3px;
        display: block;
        justify-content: flex-start !important;
    }
    .custom_range ul li :deep(input){
        width: 100%;
        border: 1px solid #cdcdcd;
        height: 30px;
        border-radius: 3px;
        padding: 0 5px 0 10px;
        color: #808080;
        font-size: 12px;
        line-height: 15px;
    }
    .custom_range .save_btn{
        padding: 7px 15px;
        background: #2f7eed;
        color: #fff;
        width: 100%;
        border-radius: 3px;
        cursor: pointer;
        font-size: 12px;
        line-height: 14px;
    }

    .widgets_wpr{
        padding: 0;
        background: transparent;
        border: 0;
        box-shadow: none;
        height: auto;
    }
    .widgets_wpr ul{
        width: 100%;
        justify-content: flex-start;
    }
    .widgets_wpr ul li{
        height: 42px;
        background: #fff;
    }
    .widgets_wpr ul li.checkbox_wpr{
        border: 0;
        width: auto;
        display: flex;
        align-items: center;
        margin: 0 15px;
    }
    .widgets_wpr li.search{
        flex: 0 1 400px;
        width: 100%;
        padding: 0 70px 0 15px;
        margin-left: auto;
    }
    .widgets_wpr li.search input[type = text]{
        height: 40px;
        width: 100%;
        background: transparent;
    }
    .widgets_wpr li.search button{
        width: 40px;
        height: 42px;
        background: #2c3e50;
        font-size: 16px;
        color: #d9d9d9;
        border-radius: 0 3px 3px 0;
        position: absolute;
        right: 0;
        top: -1px;
        cursor: pointer;
    }
    li.search_area .cleaner{
        position: absolute;
        right: 30px;
        top: 0;
        height: 33px;
        width: 30px;
        border: 0;
        z-index: 5;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 13px;
    }
    .list_wpr{
        overflow: hidden;
    }
    .order_list{
        list-style-type: none;
        margin: 0 -10px 30px -10px;
        display: flex;
        flex-wrap: wrap;
    }
    .order_list li{
        flex: 0 1 33.333%;
        padding: 10px;
    }
    .order_card{
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        padding: 25px 20px;
        /* gap: 20px; */
        /* box-shadow: 0px 1px 5px rgba(0,0,0,0.08); */
        border-radius: 10px;
        /* border: 1px solid #e9e9e9; */
        background: #fff;
        text-align: left;
    }
    .order_card .icon{
        width: 30px;
        height: 30px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #f5f5f5;
        color: #ababab;
        font-size: 15px;
    }
    .order_card h3{
        font-size: 24px;
        line-height: 30px;
        color: #121525;
        font-weight: 300;
        margin-top: 7px;
    }
    .order_card h5{
        font-size: 13px;
        line-height: 19px;
        color: #5a5a5a;
        font-weight: 400;
    }
    .actions > ul li.order_amt {
        flex: 0 1 80px;
    }
    .actions > ul li.order_amt input[type=number]{
        width: 100%;
        -moz-appearance: textfield;
    }
    .actions > ul li.order_amt input[type=number]::-webkit-inner-spin-button{
        -webkit-appearance: none;
        margin: 0;
    }
    .result_wpr table{
        min-width: 500px;
    }
    .result_wpr table td:first-child, .result_wpr table th:first-child {
        width: 50px;
        padding: 20px;
        text-align: left;
    }
    .result_wpr table td h4{
        font-size: 15px;
        line-height: 20px;
        color: #121525;
        font-weight: 500;
        margin-bottom: 5px;
    }
    .result_wpr table td h4 span{
        font-size: 11px;
        line-height: 14px;
        color: #121525;
        font-weight: 500;
        background: #e9e9e9;
        padding: 3px 8px;
        border-radius: 10px;
        display: inline-block;
    }
    .result_wpr table td h6{
        font-size: 11px;
        line-height: 15px;
        color: #121525;
        font-weight: 400;
    }
    .result_wpr table td .action_list{
        display: flex;
        justify-content: center;
        gap: 15px;
    }
    .result_wpr table td .action_list li i{
        color: #2f7eed;
        border-radius: 50%;
        font-size: 15px;
        cursor: pointer;
    }

    .order_card .icon h4 {
        display: block;
        margin-block-start: 20px;
        margin-block-end: 20px;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-weight: bold;
        font-size: 18px;
    }
    .capitalize{
        text-transform: capitalize;
    }
    .fixed_height .dropdown_wpr{
        z-index: 2;
    }
    .fixed_height .dropdown_wpr ul{
        max-height: 250px;
        overflow-y: overlay;
    }
    .fixed_height .dropdown_wpr ul::-webkit-scrollbar{
        width: 2px;
    }
    .fixed_height .dropdown_wpr ul::-webkit-scrollbar-thumb{
        border-radius: 2px;
        background: #dbdbdb;
    }
    .result_wpr.new .actions {
        z-index: 10;
    }

    .filter.result_wpr.new .actions {
        z-index: 5;
    }

    .result_wpr.new .actions > ul li.breaker{
        padding: 0;
    }
    .header_filter{
        position: fixed;
        top: 0;
        bottom: 0;
        background: #fff;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
        width: 250px;
        overflow-y: auto;
        right: -250px;
        z-index: 12;
        transition: all 0.3s ease-in-out;
        text-align: left;
    }
    .header_filter.show{
        right: 0;
    }
    .header_filter .close_btn{
        position: absolute;
        right: 20px;
        top: 20px;
        width: 20px;
        height: 20px;
        font-size: 10px;
        border-radius: 50%;
        background: #eaeaea;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #5a5a5a;
        cursor: pointer;
    }
    .header_filter .top_area{
        padding: 20px;
    }
    .header_filter .top_area h3{
        font-size: 17px;
        line-height: 22px;
        font-weight: 500;
        color: #121525;
        margin-bottom: 15px;
    }
    .header_filter .search_area {
        padding: 0 31px 0 0;
        border-radius: 17px;
        background: #f5f5f5;
        position: relative;
        margin: 15px 0;
    }
    .header_filter .search_area input[type=text] {
        font-size: 11px;
        height: 30px;
        width: 100%;
        background: transparent;
        padding: 0 0 0 15px;
        transition: all 0.3s ease-in-out;
    }
    .header_filter .search_area .search_btn {
        position: absolute;
        right: 0;
        top: 0;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        font-size: 11px;
        color: #121525;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
    .header_filter .top_area ul {
        list-style-type: none;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-top: 5px;
    }
    .header_filter .top_area ul li {
        height: 30px;
        width: 30px;
        background: #2f7eed;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.02);
        margin-right: 6px;
    }
    .header_filter .top_area ul li img {
        max-width: 16px;
        height: auto;
        filter: brightness(100);
    }
    .header_filter .action_area{
        padding: 20px;
        border-top: 1px solid #e9e9e9;
    }
    .header_filter .action_area ul li .checkbox h5 {
        font-size: 13px;
        line-height: 16px;
        font-weight: 400;
        color: #121525;
        margin: 5px 0;
    }
    .header_filter .action_area > ul > li {
        font-size: 13px;
        line-height: 18px;
        font-weight: 400;
        color: #5a5a5a;
        text-align: left;
        /* background: #f5f5f5; */
        display: flex;
        align-items: center;
        position: relative;
        cursor: pointer;
        margin-bottom: 10px;
    }
    .header_filter .action_area > ul > li.optionDrops, .header_filter .action_area > ul > li.order_amt{
        padding: 7px 15px;
        border: 1px solid #e9e9e9;
        border-radius: 20px;
    }
    .header_filter .action_area > ul > li > i{
        margin-left: auto;
    }
    .header_filter .action_area .dropdown_wpr ul{
        max-height: 250px;
        overflow-y: auto;
    }
    .header_filter .action_area .dropdown_wpr ul li{
        border: 0;
    }
    .header_filter .action_area .dropdown_wpr ul li.active{
        background: #f5f5f5;
    }
    .result_wpr.new .actions > ul li.filter_btn{
        background: #fff;
        width: 30px;
        height: 30px;
        border-radius: 3px;
        padding: 4px 6px;
        display: none;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
        cursor: pointer;
    }
    .filter_btn span{
        height: 2px;
        width: 100%;
        border-radius: 2px;
        background: #2f7eed;
        margin: 2px 0;
    }
    .filter_btn span:nth-child(2){
        width: 60%;
    }
    @media(max-width: 1399px){
        .order_list li {
            padding: 10px;
        }
        .order_card{
            padding: 20px 15px;
        }
        .order_card h5 {
            font-size: 11px;
            line-height: 16px;
        }
        .order_card h3 {
            font-size: 20px;
            line-height: 25px;
        }
    }
    @media(max-width: 1199px){
        .result_wpr table{
            margin-top: 10px;
        }
        .bar_menu {
            display: block;
        }
    }
    @media(max-width: 767px){
        .acton_header .search_area input, .result_wpr.filter .actions > ul li.search_area input{
            width: 200px;
            height: 30px;
        }
        .acton_header .search_area .search_btn, .result_wpr.filter .actions > ul li.search_area .search_btn{
            height: 30px;
        }
        .filter.acton_header .left_section .search_area{
            display: none;
        }
        .result_wpr.new:not(.filter) .actions > ul > li:not(.tutorial_btn, .filter_btn){
            display: none;
        }
        .result_wpr.new .actions > ul li.filter_btn{
            display: flex;
        }
        .result_wpr.new .actions > ul li.search_area{
            margin: 0 auto 0 0;
        }
    }
    @media(max-width: 650px){
        .order_list li {
            flex: 0 1 50%;
        }
    }
    @media(max-width: 450px){
        .acton_header .search_area input{
            width: 160px;
        }
        .custom_range{
            left: 1px;
            right: auto;
        }
        .custom_range:before{
            right: 42%;
        }
        .result_wpr.filter .actions > ul li.search_area{
            width: 250px;
        }
        .result_wpr.filter .actions > ul li.search_area input{
            padding-left: 15px;
        }
    }

    @media(max-width: 425px){
        .order_list li {
            flex: 0 1 100%;
        }
    }
    @media(min-width: 768px){
        .header_filter{
            display: none;
        }
    }
</style>